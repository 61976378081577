import { Box, Text, Tooltip } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { FactionTileInfo } from "../../../api/world"
import { Factions, SupportType } from "../../../types"
import GameIcon from "../../common/GameIcon"
import HelpText from "../../common/HelpText"
import { TileInfo } from "../../../store/MapStore"
import Number from "../../common/Number"
import { toJS } from "mobx"


interface SupportProps {
    supportType: SupportType
    power: number
    faction: Factions
    player: string
}

const SupportComponent: FunctionComponent<SupportProps> = ({ supportType, faction, power, player }) => {
    return (
        <HelpText tooltip={`${player} - ${power}%`} showHelpButton={false}>
            <Box display="inline"><GameIcon name={supportType} /></Box>
        </HelpText>
    )
}


interface SupportsProps {
    tileInfo: TileInfo
}
const Supports: FunctionComponent<SupportsProps> = ({
    tileInfo
}) => {

    const guardians = tileInfo.guardians
    const guardiansPower = guardians?.reduce((acc, guardian) => acc + guardian[0], 0) || 0

    return (
        <Box>

            {guardians?.length ?
                <Box display="flex" justifyContent={"space-between"}>
                    <Box justifyContent={"center"} display="flex" flexWrap={"wrap"} flexGrow={1}>
                        {guardians?.map((support) => {
                            return (
                                <SupportComponent supportType={SupportType.GUARDIAN} player={support[1]} power={support[0]} faction={tileInfo.faction} key={support[1]} />
                            )
                        })
                        }
                    </Box>
                    <Text as="span" pos="relative" top="-2px" ps="1">DEF Bonus : <Number value={guardiansPower} percentage /></Text>
                </Box>
                : <></>
            }

            {tileInfo.knights && 
                [Factions.BLUE, Factions.GREEN, Factions.RED, Factions.YELLOW].map((faction, i) => {
                    const knights = tileInfo.knights![i]
                    const knightsPower = knights?.reduce((acc, knight) => acc + knight[0], 0) || 0
                    if (!knightsPower) return (<Box key={faction}></Box>)
                    return (
                        <Box key={faction} bgColor={faction} display="flex" justifyContent={"space-between"} alignItems={"center"}>

                            <Box justifyContent={"center"} display="flex" flexWrap={"wrap"} flexGrow={1}>
                                {knights.map((support) => {
                                    return (
                                        <SupportComponent supportType={SupportType.KNIGHT} player={support[1]} power={support[0]} faction={tileInfo.faction} key={support[1]} />
                                    )
                                })}

                            </Box>

                            <Text as="span" pos="relative" p="2" textAlign="center">ATK Bonus : <Number value={knightsPower} percentage /></Text>
                        </Box>
                    )
                })
            }

        </Box>
    )
}

export default Supports
