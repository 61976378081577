import { useQuery } from "@tanstack/react-query";
import { anonApi } from "./base";

export interface NewsItem {
    id: number;
    title: string;
    content: string;
    created_at: string;
    modified_at: string;
    is_important: boolean;
}

export const fetchNews = async (): Promise<NewsItem[]> => {
    const news = await anonApi.get('news/list')
    return news.data
};

export async function fetchImportantNews(): Promise<NewsItem> {
    const news = await anonApi.get('news/latest-important')
    return news.data
}


export const useNews = () => {
    return useQuery({
        queryKey: ["news"],
        queryFn: fetchNews,
    })
}

export const useImportantNews = () => {
    return useQuery({
        queryKey: ["important-news"],
        queryFn: fetchImportantNews,
    })
}