import { Box, Button, Center, HStack, Heading, SimpleGrid, Stack, Text, useTheme } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { useParams } from "react-router-dom"
import { getPlayerProfile } from "../api/player"
import Loading from "../components/common/Loading"
import Panel from "../components/common/Panel"
import { useNavigate } from "react-router-dom"

const ProfilePage: FunctionComponent = () => {
    const { username } = useParams()
    const id = username?.split("-").slice(-1)[0]
    const { data, isLoading, error } = useQuery({
        queryKey: ["playerProfile", id],
        queryFn: () => getPlayerProfile(id!),
    })

    const theme = useTheme()
    const faction = data?.gameStats?.faction
    const bgColor = faction ? theme.app.factions[faction] : "orange.600"
    const navigate = useNavigate()

    return (
        <Box p="4" bgColor={bgColor} height="full">
            {isLoading && <Loading />}
            {error && <Text variant="negative">An error occurred.</Text>}

            {!isLoading && data?.player.username && (
                <Box>
                    <Heading>{data.player.username}</Heading>
                    {faction && <Text>{faction}</Text>}

                    <Stack>
                        <Center gap="0">
                            <SimpleGrid columns={[1, 1, 3, 3]} gap={1}>
                                <Panel justifyContent={"center"} alignItems={"center"} display="flex">
                                    <Text>Games played : {data.playerStats.gamesPlayed}</Text>
                                </Panel>
                                <Panel justifyContent={"center"} alignItems={"center"} display="flex">
                                    <Text>Games won : {data.playerStats.gamesWon}</Text>
                                </Panel>
                                <Panel justifyContent={"center"} alignItems={"center"} display="flex">
                                    <Text>Second : {data.playerStats.gamesSecond}</Text>
                                </Panel>
                                <Panel justifyContent={"center"} alignItems={"center"} display="flex">
                                    <Text>Third : {data.playerStats.gamesThird}</Text>
                                </Panel>
                                <Panel justifyContent={"center"} alignItems={"center"} display="flex">
                                    <Text>Best player awards : {data.playerStats.bestPlayer}</Text>
                                </Panel>
                            </SimpleGrid>
                        </Center>

                        {data.gameStats && (
                            <Box>
                                <Text>HQ level : {data.gameStats.hqLevel}</Text>
                                <Text>Soldiers sent : {data.gameStats.sentSoldiers}</Text>
                                <Text>Workers sent : {data.gameStats.sentWorkers}</Text>
                                <Text>Cases captured : {data.gameStats.casesCaptured}</Text>
                            </Box>
                        )}
                    </Stack>

                    {/* <HStack justifyContent={"end"} my="4">
                        <Button onClick={() => navigate(-1)} colorScheme="red">
                            Cancel
                        </Button>
                    </HStack> */}
                </Box>
            )}
        </Box>
    )
}

export default ProfilePage
