import {
    createMultiStyleConfigHelpers,
    defineStyleConfig,
    extendTheme,
    StyleFunctionProps,
} from "@chakra-ui/react"
import { cardAnatomy } from '@chakra-ui/anatomy'


const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(cardAnatomy.keys)

const baseStyle = definePartsStyle({
    // define the part you're going to style
    container: {
        color: "orange.50",
        boxShadow: "none",
        backgroundColor:"transparent"
    },
    header: {
        padding: 1,
        paddingTop: 2,
        borderBottom: '2px solid',
        borderImage: 'url(/ui/border.png) 15 / 10px',
        borderImageRepeat: "stretch",
        backgroundColor: 'red.800',
        // borderTopRadius: "xl"
        borderRadius: "xl",
        marginBottom: 1,
        textAlign:"center",
        boxShadow: "lg",
        _after : {
            content: '""',
            position: "relative",
            display: "block",
            top: "8px",
            left: "0",
            right: "0",
            height: "3px",
            background: "linear-gradient(to right, #8D6A3F, #CDB18E, #8D6A3F)",
        }
    },
    body: {
        backgroundColor: 'orange.500',
        borderImage: 'url(/ui/border.png) 15 / 10px',
        borderImageRepeat: "stretch",
        borderRadius: "xl",
        boxShadow: "lg",
    },
    footer: {
        paddingTop: '2px',
        backgroundColor: 'orange.500',
    },
})

const sizes = {
    md: definePartsStyle({
        container: {
            borderRadius: '0px',
        },
    }),
}

export const Card = defineMultiStyleConfig({
    baseStyle,
    sizes,
    variants: {
        funky: definePartsStyle({
            container: {
                borderColor: "red",
                borderWidth: "3px"
            }
        })
    }
})