import { Box, Container, Flex, HStack, Heading, SimpleGrid, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useEffect } from "react"
import { Outlet, useLocation, useMatch, useMatches, useNavigate, useParams } from "react-router-dom"
import Footer from "./Footer"
import Header from "./Header"
import { rootStore, useStore } from "../../store"
import { NotificationsComponent } from "./Notifications"
import { useGame, useGameId } from "../../hooks/query"
import SimpleHeader from "./SimpleHeader"
import MainMenu from "./MainMenu"
import { useQueryClient } from "@tanstack/react-query"
import { useImportantNews } from "../../api/news"

const Root: FunctionComponent = observer(() => {
    // redirect to /play if the url matches root
    const queryClient = useQueryClient()
    const { userStore, socketStore } = useStore()
    const location = useLocation()
    const navigate = useNavigate()
    const matches = useMatches()
    const inGame = matches.some((match) => match.id === "play")
    const params = useParams()
    const { data: news } = useImportantNews()

    useEffect(() => {
        if (userStore.apiToken && location?.pathname === "/") {
            navigate("/games")
        }
    }, [location?.pathname])

    useEffect(() => {
        if (params.gameId) {
            userStore.setGameId(parseInt(params.gameId, 10))
        }
    }, [params.gameId])

    useEffect(() => {
        if (inGame && userStore.player?.faction) {
            socketStore.startFactionSocket()
        }
        return () => {
            socketStore.stopFactionSocket()
        }
    }, [params.gameId, userStore.player?.faction, inGame])

    return (
        <Box>
            {/* {userStore.gameId && ( */}
            <>
                <Box
                    bgImage={[null, null, "url('/images/wallpaper.webp')"]}
                    backgroundSize="cover"
                    pos={"fixed"}
                    left={0}
                    right={0}
                    top={0}
                    bottom={0}
                    zIndex={-1}
                >
                    <Box
                        bgGradient={"linear(transparent, orange.500)"}
                        pos={"fixed"}
                        left={0}
                        right={0}
                        top={0}
                        bottom={0}
                    />
                </Box>
                <Container
                    maxW="8xl"
                    bg="orange.600"
                    centerContent
                    my={2}
                    boxShadow="xl"
                    border={"2px solid"}
                    borderColor="orange.800"
                    p="0"
                    marginBottom="50px"
                >
                    <Flex direction="column" w="full">
                        {inGame && <Header />}
                        {!inGame && <SimpleHeader />}

                        <Flex alignItems={"start"} direction={["column", "column", "row"]}>
                            {!inGame && (
                                <Box minWidth="300px" maxWidth={"500px"}>
                                    {" "}
                                    <MainMenu />
                                </Box>
                            )}
                            <Box w="full">
                                {news && !inGame && (
                                    <Box layerStyle="warning" m="4">
                                        <Text fontSize="lg">{news.title}</Text>
                                        <Text dangerouslySetInnerHTML={{ __html: news.content }} />
                                    </Box>
                                )}
                                <Outlet />
                            </Box>
                        </Flex>
                        <Footer />
                        <NotificationsComponent />
                    </Flex>
                </Container>
            </>
        </Box>
    )
})

export default Root
