import { configure } from "mobx"
import React, { createContext, ReactNode, useContext } from "react"
import RootStore from "./RootStore"

// configure({ enforceActions: "observed", computedRequiresReaction: false })

export const rootStore = new RootStore()

// create the context
const StoreContext = createContext<RootStore | undefined>(undefined);

// create the provider component
export function RootStoreProvider({ children }: { children: ReactNode }) {
  //only create the store once ( store is a singleton)
  const root = rootStore
  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>
}


// create the hook
export function useStore() {
    const context = useContext(StoreContext)
    if (context === undefined) {
      throw new Error("useRootStore must be used within RootStoreProvider")
    }
  
    return context
  }


