import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // ✅ globally default to 60 seconds
            staleTime: 60 * 1000,
            retry: 1
        },
    }
})
