import { Text } from "@chakra-ui/react";
import { FunctionComponent } from "react";



interface DateComponentProps {
    date: string | Date | number
    showTime?: boolean
    showDate?: boolean
}
const DateComponent: FunctionComponent<DateComponentProps> = ({
    date, showTime = true, showDate = true
}) => {

    const ddate = new Date(date) 
    const day = ddate.toLocaleDateString()
    const time = ddate.toLocaleTimeString(navigator.language, {hour: '2-digit', minute:'2-digit'})
    return (
        <>
            {showDate && <Text as="span">{day}</Text>}
            {showDate && showTime && <Text as="span"> - </Text>}
            {showTime && <Text as="span">{time}</Text>}

        </>
    )
}

export default DateComponent
