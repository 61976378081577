import {
    Box,
    Checkbox,
    Heading,
    List,
    ListItem,
    SimpleGrid,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react"
import { FunctionComponent } from "react"
import Loading from "../components/common/Loading"
import { useConfig, useGame, useGameId, usePlayer } from "../hooks/query"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import Button from "../components/common/Button"
import { useNavigate } from "react-router"
import { GameMode, GameModeInfo, GameStatus, joinGame, leaveLobby } from "../api/games"
import Chat from "../components/chat/Chat"
import { Victory } from "../components/stats/Victory"
import { Leaderboard } from "../components/stats/Leaderboards"
import DateComponent from "../components/common/DateComponent"
import Number from "../components/common/Number"
import Duration from "../components/common/Duration"
import { getIGChannels } from "../store/ChatStore"
import Survey from "../components/survey/Survey"
import { useSurveys } from "../api/faction"

const GameInfoPage: FunctionComponent = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate()
    const player = usePlayer()

    const gameId = useGameId()
    const { data: game } = useGame()
    const { data: surveys } = useSurveys({ refetchInterval: 10000 })
    // get map survey if any (lobby mode)
    const mapSurveys = surveys?.filter((s) => s.survey_type === "Map")
    let survey = null
    if (mapSurveys) survey = mapSurveys[0]

    const config = useConfig()
    const modeConfig = config?.modeConfig

    const leaveMutation = useMutation({
        mutationFn: () => leaveLobby(gameId),
        onSuccess: () => {
            navigate("/games")
            queryClient.invalidateQueries({ queryKey: ["games"] })
        },
    })

    const joinMutation = useMutation({
        mutationFn: () => joinGame(gameId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["game", gameId] })
        },
    })

    const channels =
        game?.status === GameStatus.COMPLETED && player && player.faction
            ? getIGChannels(player)
            : [{ id: `${gameId}_general`, name: "General" }]

    if (!game) return <Loading />

    return (
        <Box p="4" w="full">
            <Heading as="h1">Game #{game.id}</Heading>

            <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={10}>
                <Box>
                    <Text>
                        Number of players :{" "}
                        {(game.canJoinAfterStart && game.numberOfPlayers < game.maxPlayers) ||
                        !game.canJoinAfterStart ? (
                            <>
                                {game.numberOfPlayers}/{game.maxPlayers}
                            </>
                        ) : (
                            <>{game.numberOfPlayers}</>
                        )}
                    </Text>
                    <Text>Status : {game.status}</Text>
                    {game.status === GameStatus.LOBBY && (
                        <>
                            <Text>The game will starts as soon as there are enough players.</Text>
                            {game.participating && (
                                <Button onClick={() => leaveMutation.mutate()} confirm colorScheme="red">
                                    Leave lobby
                                </Button>
                            )}
                            {!game.participating && (
                                <Button onClick={() => joinMutation.mutate()} colorScheme="green">
                                    Join
                                </Button>
                            )}
                        </>
                    )}
                    {game.status === GameStatus.PLAYING && game.participating && (
                        <Button onClick={() => navigate(`/play/${game.id}`)} colorScheme="blue">
                            Play
                        </Button>
                    )}

                    {game.status === GameStatus.COMPLETED && (
                        <>
                            <Text>A faction earn 10 000 victory points or the castle has been captured.</Text>

                            <Text fontWeight={"bold"}>Winner : {game.winner}</Text>
                            <Text>
                                Date : <DateComponent date={game.victoryDate} />
                            </Text>
                        </>
                    )}
                </Box>

                <Box>
                    <Text>Game mode : {GameModeInfo[game.type].name}</Text>
                    <Text variant="discreet">{GameModeInfo[game.type].description}</Text>
                    <List>
                        <ListItem>
                            Victory points objective : <Number value={modeConfig?.vp_objective!} />
                        </ListItem>
                        <ListItem>
                            Castle capture duration :{" "}
                            {modeConfig?.castle_capture_duration && (
                                <Duration value={modeConfig?.castle_capture_duration} />
                            )}
                        </ListItem>
                        <ListItem>
                            Resources multiplier : <Number value={modeConfig?.resource_multiplier!} />
                        </ListItem>
                        <ListItem>
                            Tick duration : <Number value={modeConfig?.tick_duration!} />
                        </ListItem>
                        <ListItem>
                            Leaders : <Checkbox readOnly isChecked={modeConfig?.leaders} />
                        </ListItem>
                        {/* <ListItem>Politic system : <Checkbox readOnly checked={modeConfig?.leaders} /></ListItem> */}
                        <ListItem>
                            Sleep mode : <Checkbox readOnly isChecked={modeConfig?.sleep_mode} />
                        </ListItem>
                    </List>
                </Box>
            </SimpleGrid>

            {game.participating && survey && game.status === GameStatus.LOBBY && <Survey {...survey} />}

            {game.status === GameStatus.COMPLETED && (
                <Box mt="4" maxW="800px" mx="auto">
                    <SimpleGrid columns={[1, 1, 2, 2, 2]} spacing={10}>
                        <Victory />
                        <Leaderboard />
                    </SimpleGrid>
                </Box>
            )}

            <Chat channels={channels} />
        </Box>
    )
}

export default GameInfoPage
