import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react'
import React, { FunctionComponent } from "react"


interface LazyProps {
    active: boolean
    children: React.ReactNode
}

const Lazy: FunctionComponent<LazyProps> = ({active, children}) => {
    if(active)  return <>{children}</>
    else return <></>
}


export default Lazy