import { popoverAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys)
const baseStyle = definePartsStyle({
  // define the part you're going to style
  content: {
    bg: 'orange.800', // change the background of the content
    border: 'none',
    borderRadius: 'sm',
    boxShadow: 'md',
  },
  body: {
    // bg: 'gray.500', // change the background of the body to gray.800
  },
  footer: {

  },
  popper: { 
    // bg: 'red.800'
    // bg: 'red.800', // change the background of the popper
  },
  arrow: {
    "--popper-arrow-bg" : "#ccc", // named colors doesn't work here
  },
})
export const Popover = defineMultiStyleConfig({ baseStyle })