import { Box, SimpleGrid } from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { getSurveys, useSurveys } from "../../api/faction"
import { useGameId } from "../../hooks/query"
import Survey from "./Survey"

interface SurveyProps {}
const Surveys: FunctionComponent<SurveyProps> = ({}) => {
    const gameId = useGameId()
    const { data } = useSurveys({})


    return (
        <SimpleGrid columns={[1, 2, 2, 4]} gap="0">
            {data?.map((survey) => (
                <Survey key={survey.id} {...survey} />
            ))}
        </SimpleGrid>
    )
}

export default Surveys
