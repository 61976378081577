import { Trans } from "react-i18next";
import GameIcon from "../common/GameIcon";
import { ResourceType } from "../../types";
import { Link } from "@chakra-ui/react";

export enum QuestType {
    WOODCUTTER = 'WOODCUTTER',
    WOODCUTTER_UPGRADE = 'WOODCUTTER_UPGRADE',
    HQ_UPGRADE = 'HQ_UPGRADE',
    MINE = 'MINE',
    STORAGE = "STORAGE",
    TRAINING_CENTER = "TRAINING_CENTER",
    SEND_SOLDIERS = "SEND_SOLDIERS"
}

interface QuestStep {
    text?: JSX.Element
    textMobile?: JSX.Element
    task?: JSX.Element
    tutorial?: boolean
    postComplete?: JSX.Element
}

export const QUESTS: Record<QuestType, QuestStep[]> = {
    [QuestType.WOODCUTTER]:
        [{
            text: <Trans i18nKey="quests:woodcutter.intro">Welcome! Let's start improving your village!</Trans>,
            tutorial: true
        },
        {
            text: <Trans i18nKey="quests:woodcutter.text">Our wood income is very low, we should build a woodcutter's hut.</Trans>,
            task: <Trans i18nKey="quests:woodcutter.task">Build one woodcutter house.</Trans>
        }],
    [QuestType.WOODCUTTER_UPGRADE]:
        [{
            text: <Trans i18nKey="quests:woodcutter_upgrade.intro">Notice in the top bar your wood income increased. But we should improve it further.</Trans>,
            tutorial: true
        },
        {
            text: <Trans i18nKey="quests:woodcutter_upgarde.text">Each builing can be upgraded to generate more income.</Trans>,
            task: <Trans i18nKey="quests:woodcutter_upgarde.task">Upgrade your woodcutter house</Trans>
        }],
    [QuestType.HQ_UPGRADE]: [
        {
            text: <Trans i18nKey="quests:hq_upgrade.intro">Having some <GameIcon name={ResourceType.WOOD} /> is great, but we need others resources, like <GameIcon name={ResourceType.IRON} />. Let's upgrade our headquarters to build more stuff.</Trans>,
            tutorial: true
        },
        {
            text: <Trans i18nKey="quests:hq_upgrade.text">Each level of village allows you to build one more structure.</Trans>,
            task: <Trans i18nKey="quests:hq_upgrade.task">Upgrade your village</Trans>
        }
    ],
    [QuestType.MINE]: [
        {
            text: <Trans i18nKey="quests:mine.intro">You'll need <GameIcon name={ResourceType.IRON} /> for more advanced buildings</Trans>,
            task: <Trans i18nKey="quests:mine.task">Build a mine</Trans>
        }
    ],
    [QuestType.STORAGE]: [
        {
            text: <Trans i18nKey="quests:storage.intro">
                Notice in your top bar that the storage of your resource is limited. You will need a building to store <GameIcon name={ResourceType.WOOD}/> and <GameIcon name={ResourceType.IRON} />.
                You will have to upgrade your village first before adding another building.
            </Trans>,
            textMobile: <Trans i18nKey="quests:storage.introMobile">Notice in your top bar that the storage of your resource is limited. You will need a building to store <GameIcon name={ResourceType.WOOD}/> and <GameIcon name={ResourceType.IRON} />. 
            You will have to upgrade your village first before adding another building.</Trans>,
            tutorial: true
        },
        {
            text: <Trans i18nKey="quests:training_center.intro2">
                You may not have enough resources to build it yet. You can wait for your resources to increase or build more <GameIcon name={ResourceType.WOOD} /> and <GameIcon name={ResourceType.IRON} /> production buildings.
            </Trans>,
            tutorial: true
        },
        {
            text: <Trans i18nKey="quests:storage.text">You'll need a building to store more <GameIcon name={ResourceType.WOOD}/> and <GameIcon name={ResourceType.IRON}/></Trans>,
            task: <Trans i18nKey="quests:storage.task">Build a Storage</Trans>
        }
    ],
    [QuestType.TRAINING_CENTER]: [
        {
            text: <Trans i18nKey="quests:training_center.intro">
                We're starting to have a nice village with a proper resource income.
                We should start training soldiers to protect it.
            </Trans>,
            tutorial: true
        },
        {
            text: <Trans i18nKey="quests:training_center.text">Training centers gives you an income of soldiers.</Trans>,
            task: <Trans i18nKey="quests:training_center.task">Build a training center</Trans>
        }
    ],
    [QuestType.SEND_SOLDIERS]: [
        {
            text: <Trans i18nKey="quests:send_soldiers.intro">
                Nice, we now have our first soldier.
                Notice you unlocked the world map. Try to open it to see the world.
            </Trans>,
            tutorial: true
        },
        {
            text: <Trans i18nKey="quests:send_soldiers.intro2">
                In this game you belong to a faction (red, blue, yellow or green) along with other players. Search for your faction's HQ on the map. It's a building in one of the corner with your faction's flag.
                Each tile can be conquered by your faction. There is a condition before conquering a tile : it has to be next to a tile you own which contains at least 10 soldiers on it.
            </Trans>,
            tutorial: true
        },
        {
            text: <Trans i18nKey="quests:send_soldiers.intro3">
                Some tiles gives bonuses to your whole faction, for example, a forest tile gives you a bonus to <GameIcon name={ResourceType.WOOD} /> income and storage. Explore the map to find out more.
                The goal of the game is to conquer the castle in the middle of the map. But first, click on a tile next to your faction's HQ and send your soldier on it.
            </Trans>,
            tutorial: true
        },
        {
            text: <Trans i18nKey="quests:send_soldiers.text">Remember you can only send your soldier next to tile your faction controls.</Trans>,
            task: <Trans i18nKey="quests:send_soldiers.task">Send a soldier to a tile</Trans>,
            postComplete: <Trans i18nKey="quests:send_soldiers.postComplete">
                The tutorial is over. Continue leveling your village and discover the rest of the game. Some buildings are unlocked through achievements.
                <Link href="https://discord.gg/DsKSmetnqV" target="_blank">Join the Discord</Link > to discuss your faction's strategy and ask for help. Good luck!
            </Trans>
        }
    ],
}