import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useRef, useEffect, useState } from "react"
import { useStore } from "../../store"
import Channel from "./Channel"
import { useIsVisible } from "../../hooks/utils"
import { toJS } from "mobx"

interface ChatProps {
    channels: { id: string; name: string }[] /** channels to display */
}
const Chat: FunctionComponent<ChatProps> = observer(({ channels }) => {
    const { chatStore } = useStore()
    const ref = useRef<HTMLInputElement>(null)

    const handleTabsChange = (index: number) => {
        chatStore.setActiveChannel(index, channels[index].id)
    }

    const isVisible = useIsVisible(ref)

    useEffect(() => {
        chatStore.setIsChatVisible(isVisible)
    }, [isVisible])

    useEffect(() => {
        if (Object.values(chatStore.channels).length) chatStore.setActiveChannel(0, channels[0].id)
    }, [Object.values(chatStore.channels).length])

    useEffect(() => {
        channels.forEach((channel) => {
            chatStore.initChannel(channel.id, channel.name)
        })
    }, [])


    return (
        <Box className="chat" ref={ref}>
            <Tabs index={chatStore.activeChannel} onChange={handleTabsChange} variant="chat" padding="1">
                <TabList overflowX="auto" overflowY="hidden">
                    {channels.map((channelD) => {
                        const channel = chatStore.getChannel(channelD.id)
                        if (!channel) return null
                        return (
                            <Tab padding="1" paddingX="2" key={channel.id}>
                                {channel.name}{" "}
                                {channel.hasUnreadMessages && (
                                    <Box
                                        display={"inline-block"}
                                        borderRadius="50%"
                                        width="8px"
                                        height="8px"
                                        bgColor="red.800"
                                        position="relative"
                                        top="-6px"
                                        left="0"
                                    ></Box>
                                )}
                            </Tab>
                        )
                    })}
                </TabList>
                <TabPanels>
                    {channels.map((channelD) => {
                        const channel = chatStore.getChannel(channelD.id)
                        if (!channel) return null
                        return (
                            <TabPanel key={channel.id}>
                                <Channel channelId={channel.id}></Channel>
                            </TabPanel>
                        )
                    })}
                </TabPanels>
            </Tabs>
        </Box>
    )
})

export default Chat
