import { Box, Center, Container, Link, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"

const Footer: FunctionComponent = () => {
    const year = new Date().getFullYear()
    return (
        <Box as="footer" role="contentinfo" bg="bg-accent">
            <Container>
                <Center>
                    <Text variant="discreet">
                        Factions - {year} - Vault-35 - Buy Empire's Order on{" "}
                        <Link
                            href="https://apps.apple.com/fr/app/empires-order/id6446022485"
                            color="white"
                            target="_blank"
                        >
                            IOS
                        </Link>{" "}
                        /{" "}
                        <Link
                            href="https://play.google.com/store/apps/details?id=com.vault35.empiresorderp"
                            color="white"
                            target="_blank"
                        >
                            Android
                        </Link>
                    </Text>
                </Center>
            </Container>
        </Box>
    )
}

export default Footer
