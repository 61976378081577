import { Box, HStack, Text, Tooltip, useTheme } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent } from "react"
import { LeaderRole } from "../../api/faction"
import { useFactions, useIsUnlocked } from "../../hooks/query"
import { useStore } from "../../store"
import { Message, MessageType } from "../../store/ChatStore"
import { UnlockType } from "../../types"
import { wrapCoordinates, wrapLinks } from "../../utils/chat"
import DateComponent from "../common/DateComponent"
import GameIcon, { GameIconType } from "../common/GameIcon"
import Link from "../common/Link"

interface MessageProps extends Message {
    channelId: string
    isLast: boolean
}
const MessageComponent: FunctionComponent<MessageProps> = observer(
    ({ id, content, player, created_at, channelId, isLast, message_type }) => {
        const theme = useTheme()
        const factionColor = theme.app.factions[player?.faction!] || "orange.300"
        const { chatStore } = useStore()
        const mapIsUnlocked = useIsUnlocked(UnlockType.UI_WORLD)
        const faction = useFactions().data?.find((f) => f.id === player.faction)

        const wrappedContent = mapIsUnlocked ? wrapLinks(wrapCoordinates(content)) : content

        const channel = chatStore.getChannel(channelId)
        // check isLast because we don't show line for the last message
        const isLastRead = !isLast && channel?.lastReadMessage === id

        const isToday = new Date(created_at * 1000).toDateString() === new Date().toDateString()

        return (
            <Box>
                {player.leader_role && (
                    <Text as="span" fontSize="xs" color="purple.700">
                        {player.leader_role == LeaderRole.Leader && faction?.leader_name}
                        {player.leader_role == LeaderRole.General && "General"}
                        {player.leader_role == LeaderRole.Diplomat && "Diplomat"}{" "}
                    </Text>
                )}
                {player.premium ? (
                    // <Link
                    //     to={`/player/${player.username}`}
                    //     color={factionColor}
                    //     bgColor="black"
                    //     opacity="0.5"
                    //     p="2px"
                    //     borderRadius={"md"}
                    // >
                    //     {player.username}
                    // </Link>
                    <Link
                        to={`/player/${player.username}-${player.id}`}
                        backgroundColor={factionColor}
                        color={player.faction ? "goldenrod" : "black"}
                        border="1px solid"
                        borderColor="goldenrod"
                        textDecor={"none"}
                        p="1px"
                    >
                        {player.username}
                    </Link>
                ) : (
                    <Link to={`/player/${player.username}-${player.id}`} color={factionColor}>
                        {player.username}
                    </Link>
                )}{" "}
                {message_type === MessageType.MESSAGE && (
                    <>
                        :{" "}
                        <Text
                            className="message"
                            as="span"
                            fontSize="sm"
                            dangerouslySetInnerHTML={{ __html: wrappedContent }}
                        ></Text>
                    </>
                )}
                {message_type === MessageType.ATTACK && (
                    <Text as="span" fontSize="sm" color="blue.800" className="message">
                        commands to <GameIcon name={GameIconType.ATTACK} /> attack{" "}
                        <Text
                            as="span"
                            dangerouslySetInnerHTML={{ __html: wrappedContent }}
                        ></Text>
                        .
                    </Text>
                )}
                {message_type === MessageType.DEFEND && (
                    <Text as="span" fontSize="sm" color="blue.800" className="message">
                        commands to <GameIcon name={GameIconType.DEFEND}/> defend {" "}
                        <Text
                            as="span"
                            dangerouslySetInnerHTML={{ __html: wrappedContent }}
                        ></Text>
                        .
                    </Text>
                )}
                <Tooltip label={<DateComponent date={created_at * 1000} showTime showDate />}>
                    <Text fontSize="xs" as="span" pr="1" color="gray.300" display="inline-block" float="right">
                        <DateComponent date={created_at * 1000} showTime showDate={!isToday} />
                    </Text>
                </Tooltip>
                {isLastRead && (
                    <HStack paddingX="2">
                        <Box width="100%" height="1px" bgColor="white"></Box>
                        <Text fontSize="xs">new</Text>
                        <Box width="100%" height="1px" bgColor="white"></Box>
                    </HStack>
                )}
            </Box>
        )
    }
)

export default MessageComponent
