import { modalAnatomy as parts } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system'

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(parts.keys)

const baseStyle = definePartsStyle({
    // define the part you're going to style
    dialog: {
        backgroundColor: "orange.600",
        color: "orange.200",
        // boxShadow: "none",
        // borderRadius: "sm",
        borderImage: 'url(/ui/border.png) 15 / 10px',
        borderImageRepeat: "stretch",
        borderRadius: "xl",
        boxShadow: "md",
        // borderImageWidth: "10px 10px 0px 10px",
    },
    header: {
        borderImage: 'url(/ui/border.png) 15 / 10px',
        borderImageRepeat: "stretch",
        borderRadius: "xl",
        borderImageWidth: "10px 10px 0px 10px",
        bgColor: "red.800",
        borderBottomRadius: "none",
    },
    closeButton: {
        color: "orange.200",
        top: "1.4em",
        _hover: {
            color: "orange.50",
        },
        '.chakra-icon': {
            
            width: "2em",
            height: "2em",
        }
    },


})

export const Modal = defineMultiStyleConfig({
    baseStyle,
    variants: {
        funky: definePartsStyle({
            dialog: {
                borderColor: "red",
                borderWidth: "3px"
            }
        })
    }
})
