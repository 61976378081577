import i18n, {Module} from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const languageDetector = <Module>{
    type: "languageDetector",
    async: true, // flags below detection to be async
    detect: async (callback: Function) => {
        callback("en")
    //   try {
    //     await Localization.getLocalizationAsync().then(({ locale }: any) => {
    //     //   if(locale == "fr-FR")
    //     //     callback("fr")
    //     //   else if(locale == "pt-PT" || locale == "pt-BR")
    //     //     callback("pt")
    //     //   else if (locale== "vi-VN" || locale == "vi")
    //     //     callback("vi")
    //     //   else
    //         callback("en")
    //     })
    //   } catch (e) {
    //     console.log(e)
    //   }
    },
    init: () => { },
    cacheUserLanguage: () => { },
  }
  

i18n
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // TODO : test with true to detect missing translations
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ["common", "quests"],
    defaultNS: "common",
    // resources: {
    //   en: {
    //     translation: {
    //       // here we will place our translations...
    //     }
    //   }
    // }
  });

export default i18n;