import { Box, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { ResourceType } from "../../types"
import GameIcon from "../common/GameIcon"
import Number from "../common/Number"

interface ResourceProps {
    name: ResourceType
    value: number
    diff?: number
    variant?: "negative" | "positive"
    animated?: boolean
}

const Resource: FunctionComponent<ResourceProps> = ({
    name,
    value,
    diff,
    variant,
    animated = false,
}) => {
    return (
        <Text as={"span"} variant={variant} whiteSpace={"nowrap"}>
            <Box pos="relative" top="3px" as="span">
                <GameIcon name={name} />
            </Box>
            <Number value={value} animated={animated} />
            {diff && (
                <>
                    ({diff > 0 && `+`}
                    <Number value={diff} animated={animated} />
                    )
                </>
            )}
        </Text>
    )
}

export default Resource
