import { Spinner } from "@chakra-ui/react"
import { FunctionComponent } from "react"

interface LoadingProps {
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "full"
}
const Loading: FunctionComponent<LoadingProps> = ({ size = "xl" }) => {
    return <Spinner size={size} />
}

export default Loading
