import {
    Box,
    Center,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    SimpleGrid,
    Text,
    useDisclosure,
} from "@chakra-ui/react"
import { useQuery } from "@tanstack/react-query"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useEffect } from "react"
import Loading from "../components/common/Loading"
import EventProject from "../components/projects/EventProject"
import Project from "../components/projects/Project"
import { useStore } from "../store"
import Button from "../components/common/Button"
import FinishedProject from "../components/projects/FinishedProject"
import PastProjects from "../components/projects/PastProjects"
import ProjectsTree from "../components/projects/ProjectsTree"
import { useProjects } from "../hooks/query"

const Projects: FunctionComponent = observer(() => {
    const { data: projects, isLoading, isError } = useProjects()
    const events = projects?.event.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    const { userStore, projectsStore } = useStore()
    const { isOpen, onOpen, onClose } = useDisclosure()

    useEffect(() => {
        projectsStore.startProjectsSocket()
        return () => {
            projectsStore.stopProjectsSocket()
        }
    }, [userStore.player?.userId])

    return (
        <Box>
            {isLoading && <Loading />}
            {isError && <Box>Error</Box>}

            <Heading>Event projects</Heading>
            <Text my="2">
                Event projects are time-limited, and you are in competition with other factions. The first faction get
                100% of the bonus, the second 50% and the third 25%.
            </Text>
            <SimpleGrid columns={[1, 1, 2]} spacing={4}>
                {events && events.map((project) => <EventProject {...project} key={project.id} />)}
            </SimpleGrid>

            <SimpleGrid columns={[1, 1, 2]} spacing={4} mt="4">
                {projects?.last.map((project) => (
                    <FinishedProject {...project} key={project.id} />
                ))}
            </SimpleGrid>

            <Center mt="2">
                <Button onClick={onOpen} title="Show all previous projects">
                    Show all previous projects
                </Button>
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>Previous projects</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <PastProjects />
                        </ModalBody>

                        <ModalFooter>
                            <Button mr={3} onClick={onClose}>
                                Close
                            </Button>
                        </ModalFooter>
                    </ModalContent>
                </Modal>
            </Center>

            <Heading>Permanent projects</Heading>
            <ProjectsTree />

            {/* 


            <Heading mt="4">Permanent projects</Heading>
            <Text my="2">Send workers to improve your faction.</Text>
            <SimpleGrid columns={[1, 1, 2]} spacing={4}>
                {permanent && permanent.map((project) => <PermanentProject {...project} key={project.name} />)}
            </SimpleGrid> */}
        </Box>
    )
})

export default Projects
