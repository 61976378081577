import { action, makeObservable, observable } from "mobx"
import BaseStore from "./BaseStore"
import RootStore from "./RootStore"

export enum BuildingOrders {
    CREATED = "CREATED",
    TYPE = "TYPE",
    WOOD_PRICE = "WOOD_PRICE",
    IRON_PRICE = "IRON_PRICE"
}

export default class SettingsStore extends BaseStore {

    showMapSoldiers = false
    accessibilityMode = false
    hqViewCondensed = false
    hqViewOrder: BuildingOrders = BuildingOrders.CREATED


    constructor(rootStore: RootStore) {
        super(rootStore)
        makeObservable(this, {
            showMapSoldiers: observable,
            accessibilityMode: observable,
            hqViewCondensed: observable,
            hqViewOrder: observable,
            load: action,
            setShowMapSoldiers: action,
            setAccessibilityMode: action,
            setHqViewCondensed: action,
            setHqViewOrder: action,
        })

        this.load()
    }

    load = () => {
        const stored = localStorage.getItem('settings')
        if (stored) {
            const settings = JSON.parse(stored)
            if (settings.showMapSoldiers !== undefined)
                this.showMapSoldiers = settings.showMapSoldiers
            if (settings.accessibilityMode !== undefined)
                this.accessibilityMode = settings.accessibilityMode
            if (settings.hqViewCondensed !== undefined)
                this.hqViewCondensed = settings.hqViewCondensed
            if (settings.hqViewOrder !== undefined)
                this.hqViewOrder = settings.hqViewOrder
        }


    }
    saveToLocalStorage = () => {
        const settings = {
            showMapSoldiers: this.showMapSoldiers,
            accessibilityMode: this.accessibilityMode,
            hqViewCondensed: this.hqViewCondensed,
            hqViewOrder: this.hqViewOrder
        }
        localStorage.setItem('settings', JSON.stringify(settings))
    }

    setShowMapSoldiers = (value: boolean) => {
        this.showMapSoldiers = value
        this.saveToLocalStorage()
    }

    setAccessibilityMode = (value: boolean) => {
        this.accessibilityMode = value
        this.saveToLocalStorage()
    }

    setHqViewCondensed = (value: boolean) => {
        this.hqViewCondensed = value
        this.saveToLocalStorage()
    }

    setHqViewOrder = (value: BuildingOrders) => {
        this.hqViewOrder = value
        this.saveToLocalStorage()
    }


}
