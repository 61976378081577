import { Box, Button } from "@chakra-ui/react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { FunctionComponent } from "react"
import { Choice as ChoiceInterface, Survey, vote } from "../../api/faction"
import { useGameId, usePlayer } from "../../hooks/query"
import Progress from "../common/ProgressWithText"
import { useVote } from "./hooks"

interface ChoiceProps extends ChoiceInterface {
    surveyId: number
    surveyType: string
    pickUsers: boolean
    totalVotes: number
    voted: boolean
}
const Choice: FunctionComponent<ChoiceProps> = ({
    surveyId,
    surveyType,
    id,
    bonus,
    name,
    votes,
    totalVotes,
    voted,
    pickUsers,
    winner,
}) => {
    const gameId = useGameId()
    const player = usePlayer()
    const canVote = !pickUsers || id != player?.userId
    const voteMutation = useVote({ gameId, surveyId, choiceId: id, invalidateOnVote: surveyType === "Map" })

    return (
        <Progress
            value={votes}
            total={totalVotes}
            height={8}
            onClick={() => {
                if (canVote) voteMutation.mutate({})
            }}
            cursor={canVote ? "pointer" : "auto"}
            border={winner ? "2px solid green" : "none"}
            _hover={{
                border: "2px solid",
                borderColor: "orange.600",
            }}
        >
            {name} - {votes} {voted && "✔"}
        </Progress>
    )
}

export default Choice
