import { defineStyleConfig, defineStyle } from "@chakra-ui/react"


// const variantGhost = defineStyle((props) => {
//     const { colorScheme: c, theme } = props


//     if (c === "gray") {
//         return {
//             color: mode(`gray.800`, `whiteAlpha.900`)(props),
//             _hover: {
//                 bg: mode(`gray.100`, `whiteAlpha.200`)(props),
//             },
//             _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
//         }
//     }

//     const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme)
//     const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme)

//     return {
//         color: mode(`${c}.600`, `${c}.200`)(props),
//         bg: "transparent",
//         _hover: {
//             bg: mode(`${c}.50`, darkHoverBg)(props),
//         },
//         _active: {
//             bg: mode(`${c}.100`, darkActiveBg)(props),
//         },
//     }
// })

// https://github.com/chakra-ui/chakra-ui/blob/6d12ea81f4791f4df2953533b570178f59551b74/packages/components/theme/src/components/button.ts#L4


const variantBase = defineStyle((props) => {
    const color = props.colorScheme || "green"
    const backgroundColor = ["black", "white"].includes(color) ? color : `${color}.700`
    const borderColor = ["black", "white"].includes(color) ? color : `${color}.600`
    return {
        border: "3px solid",
        borderColor: "black",
        borderRadius: "sm",
        backgroundColor: backgroundColor,
        color: "orange.100",
        margin: "4px",
        fontWeight: "normal",
        transition: "all 0.1s ease",

        _hover: {
            _disabled: {
                borderStyle: "outset",
                bgColor: `${color}.700`,
            },
            borderStyle: "inset",
            borderRadius: "none",
            _before: {
                border: "2px solid",
                borderColor: borderColor,
                //  #788f6d",
                borderStyle: "outset",
                borderRadius: "none",
            },
        },

        _before: {
            transition: "all 0.1s ease",
            content: '" "',
            position: "absolute",
            // zIndex: -1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: "4px solid",
            borderColor: borderColor,
            borderStyle: "outset",
            borderRadius: "sm",
        },

    }
})


export const Button = defineStyleConfig({
    baseStyle: {

    },
    variants: {
        base: variantBase,
        negative: {
            color: "orange.100",
            bgColor: "red.800",
            borderColor: "red.900",
            _before: {
                borderColor: "red.600",
            },
            _hover: {
                borderColor: "red.900",
                _before: {
                    borderColor: "red.600",
                },
            },
            // linear-gradient(0deg, #650113 0%, #ca0402 100%)
        },
        ghost: {
            color: "orange.100",
            border: "2px solid",
            borderColor: "orange.600",
            borderRadius: "sm",
            bgColor: "transparent",
            _hover: {
                borderColor: "transparent",
                color: "black",
            },
            _active: {
                color: "black"
            }
        },
    },
    defaultProps: {
        variant: 'base',
        colorScheme: 'green'
    }
})