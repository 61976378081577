import { Box, Button, Text } from "@chakra-ui/react"
import { observer } from "mobx-react-lite"
import { FunctionComponent, useState, useRef } from "react"
import Panel from "../components/common/Panel"
import { usePrevious } from "../hooks/utils"



const DebugPage: FunctionComponent = () => {

    const [level, setLevel] = useState(0)
    const previous = usePrevious(level);


    return (
        <Box>
            <Button onClick={() => setLevel(level + 1)}>Add</Button>

            <Panel variant="withBorder" animKey={level} animated={true}>
                <Text>Test test test {level}</Text>
            </Panel>


        </Box>
    )
}

export default DebugPage
