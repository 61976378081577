import { Box, FormControl, FormHelperText, FormLabel, HStack, Heading, Select, Stack } from "@chakra-ui/react"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FormEvent, FunctionComponent } from "react"
import { useGameId } from "../../hooks/query"
import { HQInfo, getHQInfo, roleLabels, updateRole } from "../../api/game"
import Button from "../common/Button"
import { Role } from "../../types"

interface RoleProps {}
const RoleSelect: FunctionComponent<RoleProps> = ({}) => {
    const gameId = useGameId()
    const queryClient = useQueryClient()
    const { data: hqInfo } = useQuery({ queryKey: ["hqInfo", gameId], queryFn: () => getHQInfo(gameId) })
    const mutation = useMutation({
        mutationFn: (role: Role) => updateRole(gameId, role),
        onSuccess: () => {
            // queryClient.setQueryData(["hqInfo", gameId], (oldData: HQInfo[] | undefined) => {})
            queryClient.invalidateQueries({ queryKey: ["hqInfo", gameId] })
            queryClient.invalidateQueries({ queryKey: ["factions", gameId] })
        },
    })

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const formDate = new FormData(e.currentTarget)
        const role = formDate.get("role")?.toString()
        mutation.mutate(role as Role)
    }
    return (
        <Box>
            <form onSubmit={(e) => handleSubmit(e)}>
                <FormControl>
                    <FormLabel>
                        <Heading as="h3" size="md">
                            Your role
                        </Heading>
                    </FormLabel>
                    {hqInfo?.hq.level && (
                        <HStack alignItems={"center"}>
                            <Select
                                name="role"
                                placeholder="Select role"
                                defaultValue={hqInfo?.hq.role}
                                borderRadius="xs"
                            >
                                {Object.values(Role).map((role) => (
                                    <option key={role} value={role}>
                                        {roleLabels[role]}
                                    </option>
                                ))}
                            </Select>
                            <Button type="submit">Ok</Button>
                        </HStack>
                    )}
                    <FormHelperText>
                        Set your role so other players of your faction can see what's your focusing on.
                    </FormHelperText>
                </FormControl>
            </form>
        </Box>
    )
}

export default RoleSelect
