import { Factions } from "../../../types";
import { GameScene } from "../scene/world";

interface FlagsConstructor {
    scene: GameScene
    layer: Phaser.Tilemaps.TilemapLayer
}
const FRAMERATE = 4

/** handle mouse over highlight and tile selection */
export class Flags {

    scene: GameScene

    constructor({ scene, layer }: FlagsConstructor) {
        this.scene = scene

        scene.anims.create({
            key: 'white-flag',
            frames: scene.anims.generateFrameNumbers('flags', { start: 0, end: 1 }),
            frameRate: FRAMERATE
        });
        scene.anims.create({
            key: 'RED-flag',
            frames: scene.anims.generateFrameNumbers('flags', { start: 2, end: 3 }),
            frameRate: FRAMERATE
        });
        scene.anims.create({
            key: 'GREEN-flag',
            frames: scene.anims.generateFrameNumbers('flags', { start: 4, end: 5 }),
            frameRate: FRAMERATE
        });
        
        scene.anims.create({
            key: 'BLUE-flag',
            frames: scene.anims.generateFrameNumbers('flags', { start: 6, end: 7 }),
            frameRate: FRAMERATE
        });
        scene.anims.create({
            key: 'YELLOW-flag',
            frames: scene.anims.generateFrameNumbers('flags', { start: 8, end: 9 }),
            frameRate: FRAMERATE
        });
        const castle = scene.add.sprite(803, 780, 'flags').setScale(1.5);

        castle.play({ key: 'white-flag', repeat: -1 });

        this.scene.add.existing(castle);


        this.addFlag(scene.hqs.RED.x, scene.hqs.RED.y, Factions.RED)
        this.addFlag(scene.hqs.BLUE.x, scene.hqs.BLUE.y, Factions.BLUE)
        this.addFlag(scene.hqs.YELLOW.x, scene.hqs.YELLOW.y, Factions.YELLOW)
        this.addFlag(scene.hqs.GREEN.x, scene.hqs.GREEN.y, Factions.GREEN)

    }


    addFlag(x: number, y: number, faction: Factions) {
        const flag = this.scene.add.sprite(x * 32 + 18, y * 32 + 4, 'flags').setScale(1.5);
        flag.play({ key: `${faction}-flag`, repeat: -1 });
        this.scene.add.existing(flag);
    }

}

