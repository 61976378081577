import { Factions, SupportType } from "../types";
import { authApi } from "./base";


export interface FactionTileInfo {
    f: Factions /* faction */
    s: number /* soldiers */
    g?: [[number, string]] /* guardians */
    k?: [[[number, string]], [[number, string]], [[number, string]], [[number, string]]] /* swordsmen for blue, green, red, yellow */
    c?: boolean /* is connected to hq ? */
    cd?: number | null /* supply chain lost date */
    d?: number /* capture date */
    p?: string /* capture player */
    w?: [number, [number, number, number, number]] /* fortification workers, then improvement workers (blue, green, red, yellow) */
    v?: number /* victory points */
}

// export async function getTileInfo(x: number, y: number): Promise<TileInfo> {
//     const gameInfo = await authApi.get('world/tile_info', { params: { x, y } })
//     return gameInfo.data
// }

export async function invade(gameId: number, x: number, y: number, soldiers: number) {
    const res = await authApi.post(`/game/${gameId}/world/invade`, { x, y, soldiers })
    return res.data
}

export async function loot(gameId: number, x: number, y: number, soldiers: number) {
    const res = await authApi.post(`/game/${gameId}/world/loot`, { x, y, soldiers })
    return res.data
}

export async function support(gameId: number, x: number, y: number, supportType: SupportType) {
    const res = await authApi.post(`/game/${gameId}/world/support`, { x, y, support_type: supportType })
    return res.data
}

export async function fortify(gameId: number, x: number, y: number, workers: number) {
    const res = await authApi.post(`/game/${gameId}/world/fortify`, { x, y, workers })
    return res.data
}

export async function improve(gameId: number, x: number, y: number, workers: number) {
    const res = await authApi.post(`/game/${gameId}/world/improve`, { x, y, workers })
    return res.data
}

export async function dismantle(gameId: number, x: number, y: number, workers: number, faction: Factions) {
    const res = await authApi.post(`/game/${gameId}/world/dismantle`, { x, y, workers, faction })
    return res.data
}

interface WorldInfo {
    generated: Date
    world: FactionTileInfo[][]
}
export async function getInvasionsInfo(gameId: number): Promise<WorldInfo> {
    const gameInfo = await authApi.get(`/game/${gameId}/world/invasions_info`)
    return gameInfo.data
}
