import { Box, Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import Loading from "../components/common/Loading"
import PickASpecialization from "../components/specialization/PickASpecialization"
import Specialization from "../components/specialization/Specialization"
import { useHQInfo } from "../hooks/query"




const SpecializationPage: FunctionComponent = () => {
    const {data: info} = useHQInfo()

    if(!info) return <Loading />

    return (
        <Box>
            {!info.hq.specialization && <PickASpecialization />}
            {info.hq.specialization && <Specialization />}
            
        </Box>
    )
}

export default SpecializationPage
