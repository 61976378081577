import { useQuery } from "@tanstack/react-query";
import { useGameId } from "../hooks/query";
import { authApi } from "./base";
import { Factions, Role } from "../types";



// ROLES

interface FactionData {
    id: Factions
    my_faction: boolean
    name: string
    leader: number | null
    diplomats: number[]
    generals: number[]
    leader_name: string
    details?: {
        members: {
            id: number
            name: string
            role: Role
            hq_level: number
        }[]
        messages: {
            [key in LeaderRole]: {
                message: string
                updated_at: string
            }
        }
    }
}

export enum LeaderRole {
    Leader = "leader",
    General = "general",
    Diplomat = "diplomat",
}


export async function getFactions(gameId: number): Promise<FactionData[]> {
    const response = await authApi.get(`/game/${gameId}/faction/list`)
    return response.data
}

export async function updateMessage(gameId: number, role: LeaderRole, message: string) {
    const response = await authApi.post(`/game/${gameId}/faction/message`, { role, message })
    return response.data
}

// VOTES


export interface Choice {
    id: number
    name: string
    info?: { // for map
        author: string
        name: string
        description: string
    } 
    bonus: string
    votes: number
    winner: boolean
}

export interface Survey {
    id: number
    survey_type: string
    faction: string
    number: number
    pick_users: boolean
    permanent: boolean
    end_date: string
    created: string
    choices: Choice[]
    voted: number | null
}




export async function getSurveys(gameId: number): Promise<Survey[]> {
    // get surveys
    const talents = await authApi.get(`/game/${gameId}/surveys/list`)
    return talents.data
}

export const useSurveys = ({ refetchInterval = 60 * 1000 }: { refetchInterval?: number }) => {
    const gameId = useGameId()
    const resources = useQuery({
        queryKey: ["surveys", gameId],
        queryFn: () => getSurveys(gameId),
        refetchInterval: refetchInterval,
    })
    return resources
}


export async function vote(gameId: number, surveyId: number, choice: number) {
    const response = await authApi.post(`/game/${gameId}/surveys/vote`, { survey_id: surveyId, choice })
    return response.data
}
