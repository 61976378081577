import * as Sentry from "@sentry/react";

// get debug
const debug = process.env.NODE_ENV === "development";

if (!debug) {

  Sentry.init({
    dsn: "https://d6c1f6441acef51ecd6088dd68d5eaec@o1063947.ingest.sentry.io/4506302584455168",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/factions\.pilotsystems\.net/],
      }),
      new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.02, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}