import { Box, Stack, useDimensions, useSafeLayoutEffect } from "@chakra-ui/react"
import { useSize } from "@chakra-ui/react-use-size"

import { observer } from "mobx-react-lite"
import Phaser from "phaser"
import { FunctionComponent, useEffect, useLayoutEffect, useRef, useState } from "react"
import { useConfig, useInvasionInfo } from "../../hooks/query"
import { useIsMobile, useIsVisible } from "../../hooks/utils"
import { useStore } from "../../store"
import { gameConfig } from "./config"
import SelectedTile from "./SelectedTile"
import MapUI from "./MapUI"

// tilemap with layer : https://phaser.io/examples/v2/tilemaps/blank-tilemap

const Map: FunctionComponent = observer(() => {
    const [initialized, setInitialized] = useState(false)
    const { mapStore, socketStore } = useStore()
    const worldConfig = useConfig()
    const { data: invasionInfo } = useInvasionInfo()
    const mobile = useIsMobile()
    const boxRef = useRef<HTMLInputElement>(null)

    // const dimensions = useDimensions(boxRef, false)
    // const dimensions = useSize(boxRef) ?? { width: null }

    // send config to game
    useEffect(
        function () {
            if (worldConfig && mapStore.booted) {
                window.game?.events.emit("config", worldConfig)
                // alert('send config')
                // if (emitted) game?.events.off("prestep", sendConfig)

                // window.game?.events.addListener(
                //     Phaser.Core.Events.FOCUS,
                //     () => {
                //         window.game?.input.enabled = true
                //     }
                // )
            }
        },
        [worldConfig, mapStore.booted]
    )

    // enable or disable keyboard if map is visible or not
    const setKeyboardEnableValue = (value: boolean) => {
        if (window.game)
            window.game!.input!.keyboard!.enabled = value
    }

    const isVisible = useIsVisible(boxRef)
    useEffect(() => {
        setKeyboardEnableValue(mapStore.booted && isVisible)
    }, [mapStore.booted, isVisible])

    useEffect(() => {
        if (invasionInfo && mapStore.booted) {
            window.game?.events.emit("invasionInfo", invasionInfo.world)
        }
    }, [invasionInfo?.generated, mapStore.booted])

    // remove focus on other element so we can focus on canvas
    useEffect(() => {
        const handleClick = () => {
            // check if not in menu
            const mainMenu = document.getElementById("main-menu")
            const navigation = document.getElementById("navigation")
            if (mainMenu?.contains(document.activeElement) || navigation?.contains(document.activeElement))
                (document.activeElement as HTMLElement).blur()
        }
        boxRef.current?.addEventListener("click", handleClick)
        return () => {
            boxRef.current?.removeEventListener("click", handleClick)
        }
    }, [])

    const startGame = () => {
        mapStore.setBooted(false)
        // console.log("boot game")
        // console.log(boxRef?.current)
        // console.log(dimensions)
        // console.log(dimensions)
        // console.log(document.getElementById("worldBox")?.getBoundingClientRect())
        // console.log("start game", window.game?.isBooted && dimensions?.width)
        const nav = document.getElementById("navigation")
        const mainMenu = document.getElementById("main-menu")
        const mapUI = document.getElementById("map-ui")
        // console.log(dimensions)
        if (
            !window.game &&
            !initialized &&
            !mapStore.booted &&
            boxRef.current?.clientWidth &&
            nav &&
            mainMenu &&
            mapUI
        ) {
            setInitialized(true)
            let width = 600
            let height =
                window.innerHeight -
                nav!.getBoundingClientRect().height -
                mainMenu!.getBoundingClientRect().height -
                mapUI!.getBoundingClientRect().height

            // console.log(height, document.getElementById("navigation")!.getBoundingClientRect()
            // .height, document.getElementById("main-menu")!.getBoundingClientRect()
            // .height, document.getElementById("map-ui")!.getBoundingClientRect()
            // .height, window.innerHeight)
            if (mobile) {
                // console.log(boxRef.current)
                // height = boxRef.current.getBoundingClientRect().height
                width = window.innerWidth - 12 // 10 = border size
                height -= 46 // 10 = border size
            } else {
                width = boxRef.current?.clientWidth - 330
                height -= 40
            }
            const config = { ...gameConfig, height, width }

            window.game = new Phaser.Game(config)

            // game.events.on("prestep", sendConfig)
        }
    }

    useEffect(() => {
        let interval: number | null = null
        if (!initialized) {
            startGame()
            interval = setInterval(() => {
                startGame()
            }, 50)
        }

        return () => {
            if (interval) clearInterval(interval)
        }
    }, [initialized])

    useEffect(() => {
        return () => {
            window.game?.destroy(true)
            window.game = null
            socketStore.stopWorldSocket()
        }
    }, [])

    return (
        <Box id="content-box" ref={boxRef} display={mobile ? "block" : "flex"} h="100%">
            <Stack border={[0, 0, "5px solid"]} borderColor="orange.200" gap="0">
                <Box id="phaser"></Box>
                <MapUI />
            </Stack>
            <Box w="full">
                <SelectedTile />
            </Box>
        </Box>
    )
})

export default Map
