import { Text } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import { Role as RoleInterface } from "../../types"

interface RoleProps {
    role: RoleInterface | null
}

const roles = {
    null: {
        label: "No role",
        color: "orange.200",
    },
    [RoleInterface.ECONOMY]: {
        label: "Economy",
        color: "yellow.200",
    },
    [RoleInterface.SOLDIER]: {
        label: "Soldiers",
        color: "red.200",
    },
    [RoleInterface.WORKER]: {
        label: "Workers",
        color: "blue.200",
    },
    [RoleInterface.HYBRID]: {
        label: "Hybrid",
        color: "purple.200",
    },
}

const Role: FunctionComponent<RoleProps> = ({ role }) => {
    const crole = role || "null"
    return (
        <Text color={roles[crole].color} fontSize="sm" pr="2">
            {roles[crole].label}
        </Text>
    )
}

export default Role
