import { Box } from "@chakra-ui/react"
import { FunctionComponent } from "react"
import Map from "../components/world/Map"
import { useStore } from "../store"
import AccessibleMap from "../components/world/AccessibleMap"
import { observer } from "mobx-react-lite"

const World: FunctionComponent = observer(() => {
    const { userStore, settingsStore } = useStore()
    return (
        <Box id="world">
            {settingsStore.accessibilityMode && <AccessibleMap />}
            {!settingsStore.accessibilityMode && <Map />}
        </Box>
    )
})

export default World
